<template>
  <div class="account_sell">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'AccountValuation'}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;账号估价
      </router-link>
    </div>
    <div class="list">
      <div class="steps">
        <el-steps align-center :active="active">
          <el-step title="选择估价游戏"></el-step>
          <el-step title="填写账号信息"></el-step>
          <el-step title="提交估价信息"></el-step>
        </el-steps>
      </div>
      <div class="content1" v-if="active===0">
        <div class="content1_body">
          <div class="tree">
            <div class="treeBtn" :class="treeBtnSelect == item.id ? 'treeBtnSelect':''" v-for="(item,index) in treeList"
                 @click="getGameList(item.id)"
                 :key="index">
              <span></span>
              {{ item.name }}
            </div>
          </div>
          <div class="content1_content">
            <div class="C1C_cardList" ref="nav">
              <div class="game_list" v-for="(item, index) in c1GameList" :key="index" @click="c1Next(item)">
                <img :src="item.game_image" :alt="item.label">
                {{ item.label }}
              </div>
              <div class="c1_tip"><img src="@/assets/image/AccountValuation/c1_tip.png" alt="">选择游戏后自动跳转填写账号信息</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content2" v-if="active===1">
        <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="220px" class="demo-ruleForm">
          <div class="c2CurrentSelection">
            <div class="c2Title"><img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
              <div>当前选择的游戏为</div>
            </div>
            <div class="c2SelectedContent">
                <img :src="gameItem.game_image" alt="选择游戏">{{gameItem.label}}
                <div class="resBtn" @click="resBtn">重选</div>
            </div>
          </div>
          <div class="c2ProductInfo">
            <div class="c2Title"><img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
              <div>基本信息</div>
            </div>
            <el-form-item :label="item.cat_name" :prop="item.unique_id" v-for="item in filterData" :key="item.cat_id">
              <el-select
                :popper-append-to-body="false"
                v-model="infoForm[item.unique_id]"
                :placeholder="'请选择'+item.cat_name.slice(1)"
                @input="store_cache"
                @change="inChilds(item,infoForm[item.unique_id],isStorage)">
                <el-option :label="items.name" :value="items.id" v-for="(items) in item.entries" :key="items.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="英雄数量" prop="hero_num" v-if="gameItem.value === 12">
              <el-input-number :controls="false" v-model="infoForm.hero_num" placeholder="请输入英雄数量" @input="store_cache"></el-input-number>
            </el-form-item>
            <el-form-item label="皮肤数量" prop="skin_num" v-if="gameItem.value === 12">
              <el-input-number :controls="false" v-model="infoForm.skin_num" placeholder="请输入皮肤数量" @input="store_cache"></el-input-number>
            </el-form-item>
            <el-form-item label="王者营地ID" prop="king_camp_id" v-if="gameItem.value === 13">
              <el-input-number :controls="false" v-model="infoForm.king_camp_id" placeholder="请输入营地ID" @input="store_cache"></el-input-number>
              <!-- <el-input v-model.number="infoForm.king_camp_id" placeholder="输入营地ID" @input="store_cache"></el-input> -->
              <a href="#" class="how_king" @click="camp_accountTipShow = true"><i class="el-icon-question"></i>如何查询营地账号</a>
            </el-form-item>
            <el-form-item label="贵族等级" prop="nobility_level" v-if="gameItem.value === 13">
              <el-input-number :controls="false" v-model="infoForm.nobility_level" placeholder="请输入贵族等级" @input="store_cache" :max="10"></el-input-number>
              <!-- <el-input v-model.number="infoForm.nobility_level" placeholder="请输入贵族等级" @input="store_cache"></el-input> -->
            </el-form-item>
            <el-form-item label="和平营地ID" prop="king_camp_id" v-if="gameItem.value === 11">
              <el-input-number :controls="false" v-model="infoForm.king_camp_id" placeholder="请输入营地ID" @input="store_cache"></el-input-number>
              <!-- <el-input v-model="infoForm.king_camp_id" placeholder="请输入营地ID" @input="store_cache"></el-input> -->
              <!-- <a href="#" class="how_king" @click="camp_accountTipShow = true"><i class="el-icon-question"></i>如何查询营地账号</a> -->
            </el-form-item>
            <el-form-item label="助手社区ID：（数字）" prop="camp_account" v-if="gameItem.value === 25">
              <el-input-number :controls="false" v-model="infoForm.camp_account" placeholder="请输入助手社区ID" @input="store_cache"></el-input-number>
              <!-- <el-input v-model="infoForm.king_camp_id" placeholder="请输入营地ID" @input="store_cache"></el-input> -->
              <!-- <a href="#" class="how_king" @click="camp_accountTipShow = true"><i class="el-icon-question"></i>如何查询营地账号</a> -->
            </el-form-item>
            <el-form-item :label="item.cat_name" v-for="(item) in filterOptions" :key="item.id">
              <el-select
                :popper-append-to-body="false"
                v-model="infoForm[item.cat_id]"
                filterable
                multiple collapse-tags placeholder="请输入搜索关键字"
                @input="store_cache">
                <el-option
                  v-for="items in item.entries"
                  :key="items.id"
                  :label="items.name" :value="items.id">
                </el-option>
              </el-select>
              <span class="curr_choose" v-if="getTipsInfo(infoForm[item.cat_id],item.entries)">当前选择：<i v-html="getTipsInfo(infoForm[item.cat_id],item.entries)"></i></span>
            </el-form-item>
            <!-- <el-form-item label="上传图片" prop="cdata" v-if="gameItem.value === 8">
              <el-upload
                action="#"
                list-type="picture-card"
                multiple
                :on-change="(e)=>{
                  addChange(e,'cdata')
                }"
                :file-list="fileList"
                :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.file_path" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file,'cdata')">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" top="10vh">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item> -->

            <el-form-item label="角色图鉴" prop="cdata" v-if="gameItem.value === 26 || gameItem.value === 8">
              <span slot="label" >{{gameItem.value === 26?'角色图鉴':'上传图片'}}</span>
              <el-upload
                action="#"
                list-type="picture-card"
                multiple
                ref="uploadcdata"
                :on-change="(e)=>{
                  addChange(e,'cdata')
                }"
                :file-list="fileList"
                :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.file_path" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file,'cdata')">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <a href="#" class="how_king" @click="ys_accountTipShow = true"><i class="el-icon-question"></i>如何获取全部角色图标</a>
            </el-form-item>
            <el-form-item label="武器图鉴" prop="armsData" v-if="gameItem.value === 26">
              <el-upload
                action="#"
                list-type="picture-card"
                multiple
                ref="uploadarmsData"
                :on-change="(e)=>{
                  addChange(e,'armsData')
                }"
                :file-list="fileListA"
                :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.file_path" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file,'armsData')">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" top="10vh">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>

            <div class="melee_weapon" v-for="(item) in filterGrouping" :key="item.id">
              <div class="mw_title">{{item.cat_name}}（选填）</div>
              <el-form-item
                label-width="120px"
                :label="items.cat_name"
                v-for="items in item.entries"
                :key="items.cat_id">
                <el-select
                  :popper-append-to-body="false"
                  v-model="infoForm[items.cat_id]"
                  multiple collapse-tags placeholder="请输入搜索关键字"
                  filterable
                  @input="store_cache"
                  @change="inChilds(items,infoForm[items.cat_id])">
                  <el-option
                    v-for="itemss in items.entries"
                    :key="itemss.id"
                    :label="itemss.name" :value="itemss.id">
                  </el-option>
                </el-select>
                <span class="curr_choose" v-if="getTipsInfo(infoForm[items.cat_id],items.entries)">当前选择：<i v-html="getTipsInfo(infoForm[items.cat_id],items.entries)"></i></span>
              </el-form-item>
            </div>
          </div>
          <div class="c2ProductInfo">
            <div class="c2Title"><img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
              <div>联系方式</div>
            </div>
            <el-form-item label="QQ号" prop="qq">
              <el-input v-model="infoForm.qq" placeholder="请输入QQ号" @input="store_cache"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wx">
              <el-input v-model="infoForm.wx" placeholder="请输入微信号" @input="store_cache"></el-input>
            </el-form-item>
          </div>
          <div class="c2RemarkConnect">
            <div class="c2Title"><img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
              <div>备注说明</div>
            </div>
            <el-form-item label="备注说明">
              <el-input
                type="textarea"
                class="remark_st"
                placeholder="如账号估价有特殊需求，请输入......"
                @input="store_cache"
                v-model="infoForm.remark">
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="c2SubmitBtn">
          <div id="c2SubmitBtn" @click="c2Next('infoForm')">立即发布</div>
        </div>
      </div>
      <div class="content3" v-if="active===2">
        <div class="c3Img">
          <img src="@/assets/image/AccountDeal/sellSuccess.png" alt="">
          <div class="c3Img_text">
            <div class="c3_success">恭喜您估价信息提交成功！</div>
            <div class="c3_ground">
              <div class="c3G_id">预计<span>24小时内</span>出结果</div>
              <div class="c3G_text">请留心消息中心系统通知～</div>
            </div>
          </div>
        </div>
        <div class="c3_tip">(上架问题请联系在线客服)</div>
        <a class="c3Btn c3CusSerBtn" v-if="gameItem.game_id===10" @click="cusSerBtnClicks"
                 href="#">
          联系客服
        </a>
        <a class="c3Btn c3CusSerBtn" v-else @click="$accessLink(sell_customer)"
          :href="`${sell_customer}?u_cust_id=${$store.state.userData.user_id}&u_cust_name=${$store.state.userData.nickname}`" target="_blank">
          联系客服
        </a>
        <router-link :to="{name:'Home'}" class="c3Btn">返回首页</router-link>
      </div>
    </div>
    <!-- 选择游戏缓存弹窗 -->
    <el-dialog
      :visible.sync="UnpublishedDialogVisible"
      custom-class="Unpublished"
      :show-close="false"
      width="414px"
      top="35vh"
      center>
          <span>
            <div id="UPS_main">
              <div class="UPS_info">
                <div class="UPS_name">{{gameItem.label}}存在未发布数据</div>
                <div class="UPS_text">是否继续填写上次编辑的内容</div>
              </div>
              <div class="UPS_btn">
                <button class="UPS_reset" @click="resetnt(1)" target="_blank">重新编辑</button>
                <button class="UPS_continue" @click="resetnt(2)" target="_blank">继续填写</button>
              </div>
            </div>
            <img class="UPS_close" src="@/assets/image/AccountDeal/close.png" alt="" @click="UnpublishedDialogVisible = false">
          </span>
    </el-dialog>
    <!-- 王者营地提示弹窗 -->
    <el-dialog
      :visible.sync="camp_accountTipShow"
      custom-class="camp_accountDialog"
      top="10vh"
      :show-close="false"
      center
    >
      <div id="camp_accountDialog">
        <img class="content" src="https://images.pxb7.com/images/upload/image/20220303/1646289484638343.png" alt="">
        <img class="btn" @click="camp_accountTipShow = false" src="@/assets/image/AccountDeal/tipClose.png" alt="">

      </div>
    </el-dialog>
    <!-- 原神角色图鉴提示框 -->
    <el-dialog
      :visible.sync="ys_accountTipShow"
      custom-class="camp_accountDialog"
      top="10vh"
      :show-close="false"
      center
    >
      <div id="ys_accountDialog">
        <img class="content" src="https://images.pxb7.com/images/upload/image/20220324/1648085455670824.png" alt="">
        <img class="btn" @click="ys_accountTipShow = false" src="@/assets/image/AccountDeal/tipClose1.png" alt="">

      </div>
    </el-dialog>
    <!-- 步骤2提交弹窗 -->
    <el-dialog
      :visible.sync="EstimateSubmission"
      custom-class="Unpublished"
      :show-close="false"
      width="414px"
      top="35vh"
      center>
          <span>
            <div id="UPS_main">
              <div class="UPS_info">
                <div class="UPS_name">是否确认提交估价</div>
              </div>
              <div class="UPS_btn">
                <button class="UPS_continue ESconfirm" @click.once="ESsubmit" target="_blank">确认</button>
              </div>
            </div>
            <img class="UPS_close" src="@/assets/image/AccountDeal/close.png" alt="" @click="EstimateSubmission = false">
          </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  apiGameList,
  apiProductCategory,
  apiValuation,
  apiProductPcate,
  apiUploadPicture
} from '@/request/API'
import storage from '@/util/storage'
/**
 * 表单验证失败时 滚动到第一个error位置
 */
const scrollIntoView = (className = "is-error", block = "center") => {
    Promise.resolve().then(() => {
       const target = document.getElementsByClassName(className);
     if (target.length) {
        target[0].scrollIntoView({
           behavior: "smooth",
           block: block,
       });
    }
  });
};
export default {
  name: '',
  props: {},
  data() {
    return {
      //c1 test
      //左侧树
      treeList: [
        {name: '全部游戏', id: ''},
        {name: '网络游戏', id: 1},
        {name: '手机游戏', id: 2},
      ],
      //左侧树 选中项
      treeBtnSelect: '',

      // 未发布数据弹窗
      UnpublishedDialogVisible: false,
      // 提交估价确认
      EstimateSubmission: false,
      //c1
      active: 0,
      c1GameList: [],
      c1Form: {},

      //c2
      gameItem: {},
      filterData: [],
      filterOptions: [],
      filterGrouping: [],
      formKeyList: {},

      dialogImageUrl:'',
      dialogVisible: false,
      fileList: [],
      fileListA: [],
      filePath: [],
      filePathA: [],
      //王者荣耀 营地账号提示
      camp_accountTipShow: false,
      // 原神 角色图鉴提示
      ys_accountTipShow: false,

      //游戏账号表单校验规则
      rules: {
        skin_num: [
          {required: true, message: '请输入拥有皮肤数量', trigger: 'blur'}
        ],
        hero_num: [
          {required: true, message: '请输入拥有英雄数量', trigger: 'blur'}
        ],
        qq: [
          {required: true, message: '请输入QQ号', trigger: 'blur'}
        ],
        wx: [
          {required: true, message: '请输入微信号', trigger: 'blur'}
        ],
        // qq: [
        //   {required: false, validator: this.validatePass, trigger: 'blur'}
        // ],
        // wx: [
        //   {required: false, validator: this.validatePass1, trigger: 'blur'}
        // ],
        //
        king_camp_id: [
          {required: true, message: '请输入营地账号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{6,11}$/, message: '营地账号格式错误'}
        ],
        camp_account: [
          {required: true, message: '请输入助手社区ID', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{4,11}$/, message: '助手社区ID格式错误'}
        ],

        cdata: [
          {required: true, validator: this.validatecdata, trigger: 'change'}
        ],
        armsData: [
          {required: true, validator: this.validatearmsData, trigger: 'change'}
        ],
        nobility_level: [
          {required: true, message: '请输入贵族等级', trigger: 'blur'}
        ],
      },

      //游戏账号表单
      infoForm: {
        cdata:[],
        armsData:[],
        skin_num: undefined,
        hero_num: undefined,
        king_camp_id: undefined,
        nobility_level: undefined,
        qq:'',
        wx:'',
        remark:'',
        camp_account:''
      },
      // 客服
      sell_customer: '',
      // 是否是缓存数据
      isStorage:false
    }
  },
  methods: {
    cusSerBtnClicks() {
      let routeData = this.$router.resolve({
        name: 'CustomerSer',
        query: {
          game_id: this.gameItem.game_id
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 查看上传的图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.file_path;
      this.dialogVisible = true;
    },
    // 删除上传的图片
    handleRemove(file, key) {
      if(key=='cdata'){
        this.fileList.splice(this.fileList.findIndex(item => item.uid === file.uid), 1)
        this.filePath.splice(this.filePath.findIndex(item => item.uid === file.uid), 1)
      }else{
        this.fileListA.splice(this.fileListA.findIndex(item => item.uid === file.uid), 1)
        this.filePathA.splice(this.filePathA.findIndex(item => item.uid === file.uid), 1)
      }
      this.store_cache()
    },
    /**
     * 图片上传
     * @params key 图片上传字段名称
    */
    addChange(file,key) {
      let data = new FormData()
      data.append('file', file.raw)
      apiUploadPicture(data).then(res => {
        let data = new Object()
        data.path = res.data.file_path
        data.uid = file.uid
        file.file_path = res.data.file_path
        this.infoForm[key].push(data.path)

        if(key=='cdata'){
          this.fileList.push(file)
          this.filePath.push(data)
          this.$refs.infoForm.validateField('cdata');
        }else{
          this.fileListA.push(file)
          this.filePathA.push(data)
          this.$refs.infoForm.validateField('armsData');
        }
        this.store_cache()
      }).catch(err => {
        this.$refs['upload'+key].uploadFiles = this.$refs['upload'+key].uploadFiles.filter(val => val.uid !== file.uid)
        this.$message({
          type: "error",
          center: true,
          message: err.msg + '(5M以内的png或jpg格式的图片)'
        })
      })
    },
    /**
     * 多选框显示当前选择内容
     */
    getTipsInfo(key,list){
      let info = [];
      if(list&&key){
        list.map(item=>{
          if(key.indexOf(item.id)!=-1){
            info.push(item.name)
          }
        })
      }
      return info.join(' / ')
    },
    /**
     *获取是否缓存
     * */
    resetnt(n){
      if(n===1){
        storage.removeItem(this.gameItem.game_alias+this.gameItem.value)
        this.UnpublishedDialogVisible = false;

      } else {
        let storageinfo = storage.getItem(this.gameItem.game_alias+this.gameItem.value)
        this.UnpublishedDialogVisible = false
        this.infoForm = storageinfo.infoForm || {};
        this.fileList = storageinfo.fileList;
        this.fileListA = storageinfo.fileListA;
        this.filePath = storageinfo.filePath;
        this.filePathA = storageinfo.filePathA;
        this.isStorage = false
        if(this.infoForm.childList){
          let childList = this.infoForm.childList;
          for(let i in this.infoForm){
            if(childList.indexOf(i)!=-1){
              this.inChilds({unique_id:i,haveChild:true}, this.infoForm[i],this.isStorage)
            }
          }
        }

      }
      this.active = 1;
      this.$nextTick(()=>{
        this.$refs['infoForm'].clearValidate();
        if(n===1){
          this.$refs.infoForm.resetFields();
        }
      })
    },
    // haveChild数据


    inChilds(item, items,isSt){
      if(item.haveChild){
        if(this.infoForm.childList){
          if(this.infoForm.childList.indexOf(item.unique_id)==-1){
            this.infoForm.childList.push(item.unique_id)
          }
        }else{
          this.infoForm.childList = [item.unique_id]
        }
        apiProductPcate({
          game_id: this.gameItem.value,
          pid: items
        }).then(res => {
          for (let resKey in res.data.list) {
            this.filterData.forEach((Ditems, Dindexs) => {
              if (Ditems.cat_id === parseInt(resKey)) {
                  Ditems.entries=[]
                  res.data.list[resKey].forEach((keyItem, keyIndex) => {
                  Ditems.entries.push(keyItem)
                })
              }
            })
          }

          if(this.isStorage){
            for(let i in this.formKeyList){
              if(this.formKeyList[i]==res.data.pcat_id){
                let val  = this.infoForm[i]?'':this.infoForm[i]
                this.$set(this.infoForm,i,val)
              }
            }
          }
          this.isStorage = true
        })
      }
      this.$forceUpdate();
    },
    // 将估号数据存入缓存
    store_cache(){
      let newForm = {}
      let infoForm = this.infoForm || {};
      for(let i in  infoForm){
        if(infoForm[i]){
          if(typeof infoForm[i]!='number'){
            if(infoForm[i].length>0){
              newForm.infoForm = newForm.infoForm || {};
              newForm.infoForm[i] = infoForm[i]
            }
          }else{
            newForm.infoForm = newForm.infoForm || {};
          newForm.infoForm[i] = infoForm[i]
          }
        }
      }
      let fileList = this.fileList || [];
      if(fileList.length>0){
        newForm.fileList = this.fileList
      }
      let fileListA = this.fileListA || [];
      if(fileListA.length>0){
        newForm.fileListA = this.fileListA
      }
      let filePath = this.filePath || [];
      if(filePath.length>0){
        newForm.filePath = this.filePath
      }
      let filePathA = this.filePathA || [];
      if(filePathA.length>0){
        newForm.filePathA = this.filePathA
      }
      if(Object.keys(newForm).length>0)
      storage.setItem(this.gameItem.game_alias+this.gameItem.value,newForm)
    },
    /**
     * 获取游戏列表
     */
    getGameList(type) {
      this.treeBtnSelect = type
      apiGameList({
        class_id: type,
      }).then(res => {
        this.c1GameList = []
        res.data.forEach((item, index) => {
          if(item.assess_on == 1){
            let option = new Object()
            option.value = item.game_id
            option.label = item.game_name
            option.game_alias = item.game_alias
            option.game_image = item.game_image
            this.c1GameList.push(option)
          }
        })
      })
    },
    // 图片校验
    validatearmsData  (rule, value, callback) {
      this.filePathA = this.filePathA || []
      let val = value || []
      if (val.length == 0 &&this.filePathA.length == 0) {
        callback(new Error('请上传图片'));
      } else {
        callback();
      }

    },
    validatecdata  (rule, value, callback) {
      this.filePath = this.filePath || []
      let val = value || []
      if (val.length == 0 &&this.filePath.length == 0) {
        callback(new Error('请上传图片'));
      } else {

        callback();
      }

    },
    /**
     * 获取 游戏账号 筛选表单数据
     * @param game_id  游戏id
     */
    getProductCategory(game_id) {
      this.filterData = []
      this.filterOptions = []
      this.filterGrouping = []
      this.filePath = []
      this.filePathA = []
      this.fileList = []
      this.fileListA = []
      this.infoForm = {
        cdata:[],
        armsData:[],
        skin_num: undefined,
        hero_num: undefined,
        king_camp_id: undefined,
        nobility_level: undefined,
        qq:'',
        wx:'',
        remark:''
      }
      this.rules = {
        skin_num: [
          {required: true, message: '请输入拥有皮肤数量', trigger: 'blur'}
        ],
        hero_num: [
          {required: true, message: '请输入拥有英雄数量', trigger: 'blur'}
        ],
        qq: [
          {required: true, message: '请输入QQ号', trigger: 'blur'}
        ],
        wx: [
          {required: true, message: '请输入微信号', trigger: 'blur'}
        ],
        // qq: [
        //   {required: false, validator: this.validatePass, trigger: 'blur'}
        // ],
        // wx: [
        //   {required: false, validator: this.validatePass1, trigger: 'blur'}
        // ],
        king_camp_id: [
          {required: true, message: '请输入营地账号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{6,11}$/, message: '营地账号格式错误'}
        ],
        cdata: [
          {required: true, validator: this.validatecdata, trigger: 'change'}
        ],
        armsData: [
          {required: true, validator: this.validatearmsData, trigger: 'change'}
        ],
        nobility_level: [
          {required: true, message: '请输入贵族等级', trigger: 'blur'}
        ],
        camp_account: [
          {required: true, message: '请输入助手社区ID', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{4,11}$/, message: '助手社区ID格式错误'}
        ],
      };

      apiProductCategory({
        game_id: game_id,
        type: 'assess'
      }).then(res => {
        res.data.forEach(item => {
          if (item.is_gaoji === 1) {
            if (item.haveAttr) {
              this.filterOptions.push(item)
            } else {
              this.filterGrouping.push(item)
            }
          } else {
            if(item.unique_id != 'ajg' && item.unique_id != 'rzhs'){
              this.filterData.push(item)
              this.$set(this.infoForm,item.unique_id,null)
              this.$set(this.rules, item.unique_id, [{required: true, message: '请选择'+item.cat_name.slice(1), trigger: 'change'}])
            }
          }
          this.formKeyList[item.unique_id] = item.cat_id
        })
        if(this.active == 1)
        this.$nextTick(()=>{
          this.$refs['infoForm'].clearValidate();
        })
      })
    },
    // 步骤1选取游戏
    c1Next(item) {
      if (this.$store.state.loginStatus) {
        // this.infoForm = {}
        // console.log(storage.getItem(item.game_alias+item.value))
        if(storage.getItem(item.game_alias+item.value)){
          this.UnpublishedDialogVisible = true
        }else{
          this.active = 1;
          this.$nextTick(()=>{
            this.$refs['infoForm'].resetFields();
          })

          storage.removeItem(item.game_alias+item.value)
        }
        this.getProductCategory(item.value)
        this.gameItem = {...item}
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path,
            game_name: this.item.label,
            game_id: this.item.value,
            id: this.id,
          },
        })
      }
    },

    /**
     * 步骤二 游戏账号 发布商品
     * @param formName 表单名称
     */
    c2Next(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.EstimateSubmission = true
        } else {
          scrollIntoView()
          // setTimeout(() => {
          //   var isError = document.getElementsByClassName("is-error");
          //   if (isError[0].querySelector('input')) {
          //     isError[0].querySelector('input').focus();
          //   } else if (isError[0].querySelector('textarea')) {
          //     isError[0].querySelector('textarea').focus();
          //   }
          // }, 100);
          // console.log('error submit!!');
          return false;
        }
      });
    },

    /**
     * 步骤二 重选
     */
    resBtn() {
      this.active = 0
      this.$refs['infoForm'].resetFields()
    },
    // 估价商品提交
    ESsubmit(){
      this.EstimateSubmission = false
      let prams = {};
      for(let i in this.infoForm){
        if(this.formKeyList[i]){
          prams[this.formKeyList[i]] = this.infoForm[i]
        }else if(['childList'].indexOf(i)!=-1){

        }else if(['qq','remark','hero_num','skin_num','king_camp_id','cdata','wx','armsData','nobility_level','camp_account'].indexOf(i)==-1){
          prams[i] = this.infoForm[i]
        }
      }
      let pathList = []
      let filePath = this.filePath || []
      if (filePath.length > 0) {
        this.filePath.forEach((item, index) => {
          pathList.push(item.path)
        })
      }
      let pathListA = []
      let filePathA = this.filePathA || []
      if (filePathA.length > 0) {
        this.filePathA.forEach((item, index) => {
          pathListA.push(item.path)
        })
      }
      apiValuation({
        game_id: this.gameItem.value,
        qq: this.infoForm.qq,
        wx: this.infoForm.wx,
        category:prams,
        description:this.infoForm.remark,
        cdata: JSON.stringify(this.infoForm.cdata) || JSON.stringify(pathList),
        arms_data: JSON.stringify(this.infoForm.armsData) || JSON.stringify(pathListA),
        camp_account: this.gameItem.value === 25?this.infoForm.camp_account:this.infoForm.king_camp_id,
        skin_num: this.infoForm.skin_num,
        hero_num: this.infoForm.hero_num,
        nobility_level: this.infoForm.nobility_level
      }).then(res => {
        this.sell_customer = res.data.sell_customer
        storage.removeItem(this.gameItem.game_alias+this.gameItem.value)
        if (this.active++ >= 2) this.active = 0;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.$message({
          message: "发布成功",
          center: true,
          type: "success"
        })
      }).catch(err => {
        this.$message({
          message: err.msg,
          center: true,
          type: "error"
        })
      })
    }
  },
  mounted() {
    this.getGameList('')
  },
  watch: {},
  computed: {
  }
}
</script>

<!-- 弹框 -->
<style lang="less">
.Unpublished {
  width: 358px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 33px 0px 35px;
    position: relative;

    #UPS_main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .UPS_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 19px;

        .UPS_name {
          align-self: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #353535;
          line-height: 20px;
          margin: 0 0 25px;
        }
      }

      .UPS_btn {
        display: flex;
        justify-content: space-between;
        >button{
          display: inline-block;
          width: 139px;
          height: 38px;
          border-radius: 25px;
          font-size: 16px;
          color: #000000;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
        .UPS_reset{
          border: 1px #000000 solid;
          background-color: #fff;
          margin-right: 14px;
        }
        .UPS_continue{
          border: 1px #FFDB51 solid;
          background-color: #FFDB51;
        }
        .ESconfirm{
          width: 233px;
        }
      }
    }
    .UPS_close {
      position: absolute;
      bottom: -63px;
      left: 187px;
      cursor: pointer;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
.camp_accountDialog{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto ;
  max-height: 660px;
  background:transparent;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
  #camp_accountDialog,#ys_accountDialog{
    width: 1157px;
    .content{
      width: 100%;
      object-fit: contain;
    }
    .btn{
      position: absolute;
      top: 15px;
      right: -80px;
      cursor: pointer;
    }
  }
}
</style>

<style lang='less' scoped>
/deep/ .el-select__caret {
  font-size: 18px !important;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #FCAD13;
}

/deep/ .el-input__inner:focus,/deep/ .el-textarea__inner:focus{
  border-color: #FCAD13;
}

/deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background: #FFFBF3;
}

/deep/ .el-select-dropdown__item.selected {
  color: #FCAD13 !important;
  font-weight: normal !important;
}

/deep/ .el-pagination {
  font-weight: 400;
  font-family: PingFangSC-Light, PingFang SC;
}

/deep/ .el-select-dropdown el-popper {
  min-width: 224px;
  left: 146px;
}

/deep/ .el-select-dropdown__item /deep/ .selected {
  color: #FCAD13 !important;
}
/deep/.el-select-dropdown__item.hover{
  background-color:#FFFBF3;
}

/deep/ *.popper__arrow {
  left: 345px !important;
}

/deep/ .el-upload--picture-card:hover, /deep/ .el-upload--picture-card:focus,/deep/ .el-input {
  border-color: #FCAD13;

  i {
    color: #FCAD13;
  }
}
/deep/ .el-input{
  width: 220px;
}
.infoForm_image {
  width: 100%;
  object-fit: cover;
}
/deep/ .el-input-number{
  width: auto;
}
/deep/ .el-input-number .el-input__inner{
  text-align: left;
}

.account_sell {
  width: 1200px;

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a {
      .Breadcrumb {
        font-size: 12px;
      }
    }

    a:first-of-type {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FCAD13;
    }
  }

  .list {
    width: 1110px;
    //height: 600px;
    background: #FFFFFF;
    padding: 30px 40px 74px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .steps {
      width: 450px;

      /deep/ .el-step__line-inner {
        display: none !important;
      }

      /deep/ .el-step__line {
        left: 0%;
        right: 0%;
        height: 2px;
      }

      /deep/ .is-finish > .el-step__line {
        background-color: #FFDB51;
        top: 10px;
        height: 4px;
      }

      /deep/ .is-process > .el-step__line {
        background-color: #FFDB51;
        top: 10px;
        height: 4px;
      }

      /deep/ .is-wait > .el-step__line {
        display: block;
      }

      /deep/ .is-process > .el-step__line {
        display: block;
      }

      /deep/ .el-step__icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: none;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #5B5B5B;
        background: #DDDDDD;
      }

      /deep/ .is-finish > .el-step__icon {
        background: #FFDB51;
        color: #000000;
      }

      /deep/ .is-process > .el-step__icon {
        background: #FFDB51;
        color: #000000;
      }

      /deep/ .el-step__title {
        margin: 10px 0 0 0;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #5b5b5b;
      }
    }

    .content1 {
      width: 1110px;
      //height: 498px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .c1Select {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .content1_item {
          //width: 175px;
          flex: 1;
          height: 398px;
          margin: 0 10px 0 0;
          max-width: 190px;

          .c1-item-title {
            height: 50px;
            background: #FFF7D8;
            border: 1px solid #FFE069;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #5B5B5B;
            line-height: 50px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
          }

          .c1-item-serach {
            //width: 133px;
            width: calc(~"100% - 40px");;
            margin: 4px 0;
            height: 34px;
            background: #FFFFFF;
            border-radius: 20px;
            border: 1px solid #FFE069;
            padding: 0 20px;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
          }

          .c1-item-serach::-webkit-input-placeholder {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #BFBFBF;
          }

          .c1-item-select {
            overflow: auto;
            height: 300px;
            background: #FFFFFF;
            border: 1px solid #FFE069;

            .c1-select-opitons {
              //width: 175px;
              height: 50px;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              line-height: 50px;
              text-align: center;
              cursor: pointer;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              #smqk {
                display: flex;
              }
            }

            .opitonsSelect {
              background: #FFDB51;
              font-weight: 500;
              color: #5B5B5B;
              font-family: PingFangSC-Regular, PingFang SC;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              #smqk {
                display: flex;
              }
            }

            .c1-select-opitons:hover {
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
              background: #FFDB51;
              font-weight: 500;
              color: #5B5B5B;
              font-family: PingFangSC-Regular, PingFang SC;
            }
          }

          .c1-item-select::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
            background: rgba(0, 0, 0, 0.1);
          }

          .c1-item-select::-webkit-scrollbar {
            width: 6px;
            height: 5px;
          }

          .c1-item-select::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            background: rgba(0, 0, 0, 0.3);
          }
        }

        .is_gaoji {
          margin: 0;
        }
      }

      .content1_body {
        margin: 32px 0 0;
        height: 480px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
        display: flex;
        flex-direction: row;


        .tree {
          width: 150px;
          background: #283748;
          padding: 30px 0 0;

          .treeBtn {

            width: 150px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            cursor: pointer;
          }

          .treeBtnSelect {
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #ffffff;
            color: #283748;

            span {
              display: inline-block;
              width: 7px;
              height: 20px;
              background: #ffdb51;
              border-radius: 0 3px 3px 0;
              margin: 0 36px 0 0;
            }
          }
        }

        .content1_content {
          width: 740px;
          padding: 20px 0 50px 25px;
          display: flex;
          flex-direction: column;
          position: relative;

          .C1C_cardList {
            display: flex;
            flex-direction: row;
            overflow: auto;
            flex-wrap: wrap;
            margin-left: 25px;
            &::-webkit-scrollbar{
              width: 8px;
              height: 8px;
              scrollbar-arrow-color:red;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: #FFDB51;
            }
            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background: rgba(0,0,0,0.1);
            }
            .game_list{
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 80px;
              height: 113px;
              text-align: center;
              color: #5B5B5B;
              font-size: 16px;
              line-height: 22px;
              margin:0 20px 20px 0;

              &:nth-child(7n){
                margin-right: 0;
              }
              img{
                width: 80px;
                height: 80px;
                border-radius: 20px;
                display: inline-block;
                margin-bottom: 11px;
              }
            }
            .c1_tip{
              width: 270px;
              height: 37px;
              background-color: #FFFBEB;
              position: absolute;
              bottom: 10px;
              border-radius: 37px;
              font-size: 14px;
              color: #FCA700;
              line-height: 37px;
              img{
                width: 14px;
                // height: 19px;
                vertical-align: middle;
                display: inline-block;
                margin: 0 5px 0 20px;
              }
            }
          }
        }
      }
    }

    .content2 {
      width: 1090px;
      background: #FFFFFF;
      .c2Title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FF9407;
        line-height: 28px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 32px 0;

        img {
          margin: 0 20px 0 0;
        }
      }
      /deep/ .el-select-dropdown__item.selected{
        color:#FCAD13 !important;
      }
      .c2CurrentSelection{
        padding-bottom: 34px;
        border-bottom: 2px #EBEBEB solid;
        margin-bottom: 50px;
        .c2SelectedContent {
          margin-left: 30px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 17px;
          }
        }
        .resBtn {
          display: inline-block;
            margin: 0 0 0 27px;
            width: 100px;
            height: 30px;
            background: #FFDB51;
            border-radius: 30px;
            line-height: 30px;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 400;
            color: #000000;
            text-align: center;
            cursor: pointer;
          }
      }
      .c2ProductInfo {
        padding-bottom: 34px;
        border-bottom: 2px #EBEBEB solid;
        margin-bottom: 50px;
        .how_king{
          color: #FF7979;
          font-size: 14px;
          i{
            display: inline-block;
            height: 20px;
            margin: 0 2px 0 10px;
          }
        }
        .curr_choose{
          display: block;
          font-size: 16px;
          i{
            font-style: normal;
            font-size: 14px;
            color: #606266;
          }
        }
        .melee_weapon{
          border: 1px solid #d7d7d7;
          border-radius: 10px;
          width: 890px;
          margin:0 auto 20px;
          .mw_title{
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #d7d7d7;
            color: #5b5b5b;
            font-size: 18px;
            font-weight: 600;
            text-indent: 30px;
            margin-bottom: 21px;
          }
        }
      }
      .c2RemarkConnect{

        .remark_st{
          width: 714px;
          height: 200px;
          /deep/.el-textarea__inner{
              resize: none;
              width: 100%;
              height: 100%;
          }
        }
      }
      .c2SubmitBtn{
        width: 300px;
        height: 50px;
        background-color: #FFDB51;
        border-radius: 30px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        div{
          font-size: 18px;
          line-height: 50px;
        }
      }
    }

    .content3 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .c3Img {
        width: 1110px;
        padding: 59px 0 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 197px;
          height: 130px;
          object-fit: cover;
          margin: 0 26px 0 0;
        }

        .c3Img_text {
          .c3_success {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #5b5b5b;
            margin: 0 6px 6px 19px;
          }

          .c3_ground {
            width: 355px;
            background: #ffffff;
            border: 1px solid #e0e9f2;

            .c3G_id {
              padding: 6px 0 7px 19px;
              background: #a0cbf6;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #ffffff;
              span{
                color: #0078EF;
              }
            }

            .c3G_text {
              padding: 8px 0 10px 19px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Light;
              font-weight: 300;
              color: #8f8f8f;

              span {
                font-weight: 500;
                color: #fcad13;
              }
            }
          }


        }

      }

      .c3Btn {
        width: 298px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 30px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }

      .c3CusSerBtn {
        margin: 0 0 10px 0;
        background: #FFDB51;
        border: 1px solid #FFDB51;
      }

      .c3_tip {
        margin: 0 0 8px 0;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #C9C9C9;
      }
    }
  }
}
</style>
